export const SUCCESS = 'success';
export const FAILED = 'failed'


export const WAITING = 'waitting';

export const MESSAGE_STATUS = {
    DRAFT: "e5b6ba88-248b-4bfd-bc0a-d7777e5b475a",
    SENT: "c3ea1eb4-f3d0-4d11-87a1-63d8c5443542",
    READ: "032f0284-8448-499a-91a1-4ff1d5125c26",
    ARCHIVED: "b6c4e6f8-5e56-45d3-ae84-ed27d7fd36e6",
    DELETED: "7da8fa00-37b5-4af6-b3b2-0477a9e2e1fe"
}
export const TICKET_STATUS = {
    TICKET_DRAFT: "f270fed1-ec15-469d-9748-8e694b111796",
    UNREAD_SUPPORT_TEAM: "6653c70e-af67-48f8-bf0f-113bd6cb20a4",
    READ_SUPPORT_TEAM: "504548f9-6bfa-4654-88e9-3a31ffcc2047",
    ANSWERED_SUPPORT_TEAM: "203dace4-8e43-45cf-ad3a-8f4d510baac1",
    READ_CUSTOMER: "430eea06-f186-42d5-a673-9a625233da1a",
    TICKET_CLOSED: "2acdf285-02b1-46d1-b3f7-8d5b501f88b7"
}

export const lockOutTimerMessage = {
    RESET: 'ResetTimer',
    START: 'StartTimer',
    LOGOUT: 'Logout',
    ACTIVITY: 'ActivityDetected'
}
