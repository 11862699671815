import {useState} from "react";
import {useError} from "../../context/ErrorContext";
import {
    createTicket,
    ticket,
    ticketPriorities,
    tickets,
    ticketSubjects,
    updateRateTicket,
    updateTicketStatus
} from "../../services";
import {ticketPrioritiesToList, ticketSubjectsToList} from "../utils/toList";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useModel} from "../../context/ModelContext";
import {useLocation} from "react-router-dom";
import {ITicketData} from "../interfaces/ITicketData";
import {TICKET_STATUS} from "../constant";
import {ITicketList} from "../interfaces/ITicketList";
import {FormikState, FormikValues} from "formik";
import {SupportValuesProps} from "../formValidator/supportFormValidator";

export const useTicket = () => {

    const [subjectList, setSubjectList] = useState<{label: string, value: string}[] | []>([]);
    const [priorityList, setPriorityList] = useState<{label: string, value: string}[] | []>([]);
    const [ticketList, setTicketList] = useState<ITicketList[] | []>([]);
    const [ticketData, setTicketData] = useState<ITicketData | null>(null);
    const {setError} = useError();
    const {workSpace} = useWorkSpace();
    const {model} = useModel();
    const {pathname} = useLocation();

    const loadSubjects = async () => {
        try {
            const data = await ticketSubjects();
            setSubjectList(ticketSubjectsToList(data))
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadPriority = async () => {
        try {
            const data = await ticketPriorities();
            setPriorityList(ticketPrioritiesToList(data))
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadTickets = async () => {
        setTicketList([])
        try {
            const data = await tickets();
            setTicketList(data.filter(item => item.ticket.related_ticket_uuid === null));
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadTicket = async (uuid: string) => {
        try {
            const data = await ticket(uuid);
            setTicketData(data);
            console.log(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleUpdateRate = async (uuid: string, rate: number) => {
        try {
            await updateRateTicket(uuid, rate);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleSubmitTicket = async (values: FormikValues, resetForm: (nextState?: Partial<FormikState<SupportValuesProps>>) => void, related_uuid: string) => {
        const formValues = {
            title: values.title.trim(),
            text: values.body.trim(),
            page_url: pathname,
            ticket_type: "question",
            ticket_rating: 0,
            email_sent: false,
            ticket_subject_uuid: values.subject.value,
            ticket_status_uuid: TICKET_STATUS.UNREAD_SUPPORT_TEAM,
            workspace_uuid: workSpace ? workSpace?.uuid : null,
            model_uuid: model ? model.uuid : null,
            support_agent_uuid: "e77faaf6-eb21-406e-82cb-67b65d64481d",
            related_ticket_uuid: related_uuid !== "" ? related_uuid : null,
            ticket_priority_uuid: values.priority.value
        }

        try {
            const data = await createTicket(formValues);
            if (data) {
                resetForm();
                setError({message: "Your Ticket Submitted Successfully.", type: 'success'})
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const editTicketStatus = async (uuid: string, status: string) => {
        try {
            const values = {
                status_uuid: status
            }
            await updateTicketStatus(uuid, values);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    return {priorityList,
        subjectList,
        handleSubmitTicket,
        loadTickets,
        ticketList,
        loadPriority,
        loadSubjects,
        loadTicket,
        ticketData,
        handleUpdateRate,
        editTicketStatus
    }
}